import React from 'react'


function Footer() {
  return (
    <div>
      <div className='footer'>

      </div>
    </div>
  )
}

export default Footer